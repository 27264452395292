'use client';

import React, { useContext } from 'react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import cx from 'classnames';
import Typography from 'components/Typography';
import { Link } from 'utils/navigation';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { ReactComponent as Logo } from 'public/assets/icons/logo.svg';
import styles from './Footer.module.scss';
const Footer: React.FC = () => {
  const t = useTranslations('Footer');
  const tNav = useTranslations('navigation');
  const {
    isUkraineGeo
  } = useContext(ConfigurationContext);
  return <div className={styles.root} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <footer className={styles.footer}>
        <div className={styles.links}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo} data-sentry-element="Logo" data-sentry-source-file="Footer.tsx" />
          </div>
          <div className={cx(styles.column, styles.catalog)}>
            <Typography variant="h10" color="gray" className={styles.headline} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              {t('catalog')}
            </Typography>
            <Link href="/channels" prefetch={false} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                {tNav('channels')}
              </Typography>
            </Link>
            <Link href="/posts" prefetch={false} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                {tNav('posts')}
              </Typography>
            </Link>
            {isUkraineGeo && <Link href="/cities" prefetch={false}>
                <Typography variant="h9" color="white">
                  {tNav('cities')}
                </Typography>
              </Link>}
            <Link href="/channel/add" prefetch={false} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                {tNav('addChannel')}
              </Typography>
            </Link>
          </div>
          <div className={cx(styles.column, styles.channels)}>
            <Typography variant="h10" color="gray" className={styles.headline} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              {t('channels')}
            </Typography>
            <NextLink href="https://t.me/tglist" prefetch={false} target="_blank" rel="noopener noreferrer nofollow" data-sentry-element="NextLink" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                @TGlist
              </Typography>
            </NextLink>
            <NextLink href="https://t.me/tglist_com" prefetch={false} target="_blank" rel="noopener noreferrer nofollow" data-sentry-element="NextLink" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                @TGlist_com
              </Typography>
            </NextLink>
            <NextLink href="https://t.me/tglist_chat" prefetch={false} target="_blank" rel="noopener noreferrer nofollow" data-sentry-element="NextLink" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                @TGlist_chat
              </Typography>
            </NextLink>
          </div>
          <div className={cx(styles.column, styles.bots)}>
            <Typography variant="h10" color="gray" className={styles.headline} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              {t('bots')}
            </Typography>
            <NextLink href="https://t.me/tglist_analytics_bot" prefetch={false} target="_blank" rel="noopener noreferrer nofollow" data-sentry-element="NextLink" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                @tglist_analytics_bot
              </Typography>
            </NextLink>
            <NextLink href="https://t.me/tglist_support_bot" prefetch={false} target="_blank" rel="noopener noreferrer nofollow" data-sentry-element="NextLink" data-sentry-source-file="Footer.tsx">
              <Typography variant="h9" color="white" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                @tglist_support_bot
              </Typography>
            </NextLink>
          </div>
        </div>
        <div className={styles.info}>
          <Typography variant="h10" color="gray" className={styles.copy} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
            <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('copy', {
              now: new Date()
            })
          }} />
          </Typography>
          <div className={styles.designedBy}>
            <Typography variant="h10" color="gray" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              {t('design')}{' '}
            </Typography>
            <NextLink href="https://thefirstthelast.agency/" prefetch={false} target="_blank" rel="noopener noreferrer nofollow" data-sentry-element="NextLink" data-sentry-source-file="Footer.tsx">
              <Typography variant="h10" color="gray" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                The First The Last
              </Typography>
            </NextLink>
          </div>
        </div>
      </footer>
    </div>;
};
export default Footer;