// eslint-disable-next-line import/prefer-default-export
export const USER_TOKEN_NAME = 'tglu';
export const USER_REFRESH_TOKEN_NAME = 'tglur';
export const SESSION_ID_TOKEN_NAME = 'session_id_tg';
export const PINNED_TOPICS_KEY = 'pinedTopics';
export const PINNED_CITIES_KEY = 'pinedCities';
export const USER_COUNTRY_ISO = 'tgluci';
export const COOKIE_CONSENT = 'cookie_consent';
export const METRICS_ORDER_KEY = 'metricsOrder';
export const FILTERS_OPENED_KEY = 'filtersOpened';
