'use client';

import React, { createContext, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { setCookie } from 'cookies-next';
import { USER_COUNTRY_ISO } from 'constants/storage';
import { UKRAINE_COUNTRY_ID } from 'constants/geo';
interface IConfigurationContext {
  country: (string | null)[];
  countriesFilter: string;
  isUkraineGeo: boolean;
  setCountry: (country: (string | null)[]) => void;
}
export const ConfigurationContext = createContext<IConfigurationContext>({
  country: [null],
  countriesFilter: '',
  isUkraineGeo: false,
  setCountry: () => {}
});
const ConfigurationProvider: React.FC<HTMLAttributes<HTMLElement> & {
  countries: (string | null)[];
}> = ({
  children,
  countries: initialCountries
}) => {
  const [countries, setCountries] = useState<(string | null)[]>(initialCountries);
  const countriesFilterValue = useMemo(() => {
    return countries.filter(c => c !== null).join(',');
  }, [countries]);
  const setNewCountries = useCallback((newCountries: (string | null)[]) => {
    setCookie(USER_COUNTRY_ISO, newCountries.map(c => `${c}`).join(','), {
      expires: dayjs().add(1, 'year').toDate()
    });
    setCountries(newCountries);
  }, [setCountries]);
  const isUkraineGeo = useMemo(() => countriesFilterValue === UKRAINE_COUNTRY_ID, [countriesFilterValue]);
  const providerValue = useMemo(() => ({
    country: countries,
    setCountry: setNewCountries,
    countriesFilter: countriesFilterValue,
    isUkraineGeo
  }), [countries, setNewCountries, countriesFilterValue, isUkraineGeo]);
  return <ConfigurationContext.Provider value={providerValue} data-sentry-element="unknown" data-sentry-component="ConfigurationProvider" data-sentry-source-file="ConfigurationContext.tsx">{children}</ConfigurationContext.Provider>;
};
export default ConfigurationProvider;